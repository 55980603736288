// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--lU7l1";
export var footerCase = "footer-module--footerCase--Grc8r";
export var footerInfo = "footer-module--footerInfo--RyzVU";
export var footerLogo = "footer-module--footerLogo--nDLxU";
export var footerLogoSection = "footer-module--footerLogoSection--64ri8";
export var footerPages = "footer-module--footerPages--pMia6";
export var footerServices = "footer-module--footerServices--tTk9b";
export var footerSocials = "footer-module--footerSocials--nWvcn";
export var footerWild = "footer-module--footerWild--WloZS";
export var footerWildLogo = "footer-module--footerWildLogo--YjRdk";
export var footerWrap = "footer-module--footerWrap--ZF8t-";
export var mt = "footer-module--mt--yGKJ7";