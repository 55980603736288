// extracted by mini-css-extract-plugin
export var burger = "header-module--burger--ZYCyO";
export var burgerLeft = "header-module--burgerLeft--OhNzR";
export var burgerOpen = "header-module--burgerOpen--PxR-V";
export var burgerOuterWrap = "header-module--burgerOuterWrap--17l12";
export var burgerRight = "header-module--burgerRight--YaYny";
export var burgerWrap = "header-module--burgerWrap--hzROY";
export var getInTouch = "header-module--getInTouch--H-U6a";
export var header = "header-module--header--4dQNe";
export var headerWrap = "header-module--headerWrap--zMdEy";
export var innerHeaderWrap = "header-module--innerHeaderWrap--IVqkY";
export var link = "header-module--link--+sNv4";
export var linkLeft = "header-module--linkLeft--ESDKU";
export var linkRight = "header-module--linkRight--Iovi9";
export var lowerLinks = "header-module--lowerLinks--0u9r8";
export var lowerRotate = "header-module--lowerRotate--6GLri";
export var mobileLink = "header-module--mobileLink--yPp9f";
export var normalLink = "header-module--normalLink--YFljU";
export var scrollInd = "header-module--scrollInd--WsQTO";
export var socials = "header-module--socials--amdfj";